import React from "react";
import { Typography } from "@material-ui/core";
import Container from "../components/general/Container";
import MainLayout from "../components/layout/MainLayout";
import Footer from "../components/general/Footer";
import { PageProps } from "../typings";

type FourOfourProps = {} & PageProps<{}>;

const NotFoundPage: React.FC<FourOfourProps> = ({ location }) => {
  return (
    <MainLayout location={location} title={"Nicht gefunden"}>
      <Container variant="standard" padding color="primary">
        <div style={{ height: "82vh" }}>
          <Typography variant="h1">Seite nicht gefunden</Typography>
          <p>Diese Seite existiert nicht.</p>
        </div>
      </Container>
      <Footer />
    </MainLayout>
  );
};

export default NotFoundPage;
